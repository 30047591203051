import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceCreationService } from '../../device-creation/device-creation.service';
import {
  CalibrationFunction,
  DeviceDto,
} from './../../../../../../models/device.models';

@Component({
  selector: 'app-device-calibration-function',
  templateUrl: './device-calibration-function.component.html',
  styleUrls: ['./device-calibration-function.component.scss'],
})
export class DeviceCalibrationFunctionComponent implements OnInit {
  @Output() deviceUpdated = new EventEmitter<DeviceDto>();

  calibrationForms = this.fb.array([], [uniqueKeys]);
  functions = [
    {
      function_id: 'linear_function',
      formula: 'f(x) = ax + b',
      args: { a: [1, [Validators.required]], b: [0, [Validators.required]] },
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { fields: string[]; device: DeviceDto },
    private deviceCreation: DeviceCreationService,
    private ref: MatDialogRef<unknown>,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.data?.device?.calibration_functions?.forEach((fn) =>
      this.calibrationForms.push(this.createCalibrationForm(fn)),
    );

    if (!this.data?.device?.calibration_functions.length)
      this.calibrationForms.push(this.createCalibrationForm());
  }

  createCalibrationForm(fn?: CalibrationFunction) {
    const func = this.functions.find(
      (func) => func.function_id === fn?.function_id,
    );
    const formGroup = this.fb.group({
      field: [fn ? fn.field : '', [Validators.required]],
      formula: [fn ? func : ''],
      function_id: ['', [Validators.required]],
    });

    if (func) {
      this.setArgsOnForm(formGroup, func);
      const argsForm = formGroup.get('args') as UntypedFormGroup;
      Object.keys(func.args).forEach((key, i) =>
        argsForm.get(key)?.setValue(fn?.args[i]),
      );
    }

    return formGroup;
  }

  deleteCalibration(index: number) {
    this.calibrationForms.removeAt(index);
    this.calibrationForms.markAsUntouched();
    this.calibrationForms.updateValueAndValidity();
  }

  setArgsOnForm(
    form: UntypedFormGroup,
    choice?: (typeof this.functions)[number],
  ) {
    if (!choice) return;

    const argsForm = this.fb.group(choice.args);

    form.patchValue({ function_id: choice.function_id });

    // Allows the controls to be looped over with ngFor
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (argsForm.controls as any)[Symbol.iterator] = function* () {
      const keys = Object.keys(argsForm.controls);
      for (const key of keys) yield key;
    };

    form.setControl('args', argsForm);
  }

  submit() {
    const result = {
      functions: this.calibrationForms.controls
        .filter((ctrl) => ctrl.valid)
        .map((ctrl) => ({
          ...ctrl.value,
          args: Object.values(ctrl.value.args),
        })),
    };

    this.deviceCreation
      .calibrateDevice(this.data.device.device_id, result)
      .subscribe((device) => {
        this.deviceUpdated.emit(device);
        this.ref.close(true);
      });
  }
}

function uniqueKeys(formArray: AbstractControl) {
  const controls = (formArray as UntypedFormArray).controls;
  const keys = controls.map((form) => form.get('field')?.value);
  const mapper = {};
  for (const key of keys) {
    if (mapper[key]) return { uniqueKeys: true };
    else mapper[key] = 1;
  }

  return null;
}
