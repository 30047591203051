import { NestedTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { delay, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { DeviceHierarchyStoreService } from 'src/app/services/state/data/device-hierarchy/device-hierarchy-store.service';
import { HierarchyNode } from 'src/models/device-hierarchy.models';
import { DeviceCreationService } from '../device-creation/device-creation.service';
import { DeviceManagementService } from '../device-management.service';

@Component({
  selector: 'app-hierarchy-tree',
  templateUrl: './hierarchy-tree.component.html',
  styleUrls: ['./hierarchy-tree.component.scss'],
})
export class HierarchyTreeComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() selectedNode: HierarchyNode;
  treeControl = new NestedTreeControl<HierarchyNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<HierarchyNode>();

  subscriptions = new Subscription();

  constructor(
    private router: Router,
    private deviceManagement: DeviceManagementService,
    private deviceHierarchy: DeviceHierarchyStoreService,
    private deviceCreation: DeviceCreationService,
    private snack: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.deviceHierarchy.filteredHierarchyTree$
        .pipe(
          tap((hierarchy) => (this.dataSource.data = hierarchy)),
          delay(1),
        )
        .subscribe(() => this.scrollToActiveNode()),
    );

    this.subscriptions.add(
      combineLatest([
        this.deviceManagement.path$,
        this.deviceHierarchy.filteredHierarchyTree$,
      ])
        .pipe(
          map(([path]) => path),
          filter((path) => !!path?.length),
          map((path) => this.deviceHierarchy.getNodesForPath(path)),
          tap((nodes) => this.openNodes(nodes)),
        )
        .subscribe(),
    );
  }

  ngAfterViewInit(): void {
    this.scrollToActiveNode();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public isFolder(_: number, node: HierarchyNode): boolean {
    return !node.isDevice;
  }

  public nodeSelected(node: HierarchyNode): void {
    this.deviceHierarchy.filteredHierarchyTree$
      .pipe(first())
      .subscribe((hierarchy) => {
        const newPath = this.deviceHierarchy.getPathForNode(node, hierarchy);
        this.router.navigateByUrl(`home/device/manager?path=${newPath}`);
      });
  }

  private openNodes(nodes: HierarchyNode[]) {
    nodes.forEach((node) => this.treeControl.expand(node));
  }

  private scrollToActiveNode() {
    let activeNodeElement: HTMLElement | null;
    if (this.selectedNode) {
      activeNodeElement = document.getElementById(this.selectedNode.id);
      if (activeNodeElement) {
        activeNodeElement.scrollIntoView();
      }
    }
  }

  nodeIsSelected(node: HierarchyNode): boolean {
    return this.selectedNode ? this.selectedNode.id === node.id : false;
  }

  endDrag(event: DragEvent, node: HierarchyNode) {
    this.deviceManagement
      .dropDraggedItemOnNode(event, node)
      ?.pipe(
        switchMap((deviceIds) =>
          this.deviceCreation.moveDevices(
            deviceIds,
            this.deviceHierarchy.getPathForNode(node),
          ),
        ),
        switchMap(() => this.deviceHierarchy.loadDeviceHierarchy()),
      )
      .subscribe(() => {
        this.deviceManagement.resetDragData();
        this.deviceManagement.setSelectedNode(
          this.deviceHierarchy.getSelectedNodeForPath(
            this.deviceManagement.path,
          ),
        );
        this.snack.open('Device successfully moved', 'OK');
      });
  }

  allowDrop(event: DragEvent) {
    this.deviceManagement.allowDroppingDraggedItem(event);
  }

  preventDrop(event: DragEvent) {
    this.deviceManagement.preventDroppingDraggedItem(event);
  }
}
