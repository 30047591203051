import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  GeneralRouteInfo,
  Interface,
  RouteDto,
} from 'src/models/data-routing.models';
import { CreateSiteDTO } from 'src/models/site.models';
import { Organization } from './../../../../models/organization.models';

import { DevicesService } from './../../http/devices.service';
import { DataStoreService } from './data-store.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Country } from 'src/models/countries.models';
import {
  Brand,
  DecoderBluePrint,
  DeviceTypeDto,
  DeviceTypeInfo,
  Source,
} from 'src/models/device-type.models';
import {
  BrandInfo,
  DeviceAlarm,
  DeviceDto,
  DeviceFilter,
  DeviceMonitoringStats,
  DevicePictureInfo,
  DeviceUpdateInfo,
  Downlink,
  SiteNotificationConfig,
} from 'src/models/device.models';
import { Gateway, RssiHistoryData } from 'src/models/gateway.models';
import { Site } from 'src/models/site.models';
import { User, UserAlertInfo, UserInfo } from 'src/models/user.models';

@Injectable({
  providedIn: 'root',
})
export class DataLoaderService {
  constructor(
    private http: DevicesService,
    private data: DataStoreService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  //---------------------------------------------------------------------------
  // Brands
  //---------------------------------------------------------------------------

  public loadBrands(): Observable<Brand[]> {
    return this.http.getBrands().pipe(
      tap((brands) => {
        this.data.setBrands(brands);
      }),
    );
  }

  public createBrand(brand: BrandInfo): Observable<Brand> {
    return this.http.createBrand(brand).pipe(
      tap((brand) => {
        this.data.addBrand(brand);
      }),
      tap(() => {
        this.snackBar.open('Brand was successfully created!', 'Close');
      }),
    );
  }

  public updateBrand(brandId: string, brand: BrandInfo): Observable<Brand> {
    return this.http.updateBrand(brandId, brand).pipe(
      tap((brand) => {
        this.data.updateBrand(brand);
      }),
      tap(() => {
        this.snackBar.open('Brand was successfully updated!', 'Close');
      }),
    );
  }

  public deleteBrand(brandId: string): Observable<string> {
    return this.http.deleteBrand(brandId).pipe(
      tap(() => this.data.deleteBrand(brandId)),
      tap(() => {
        this.snackBar.open('Brand was successfully deleted!', 'Close');
      }),
    );
  }

  //---------------------------------------------------------------------------
  // Codecs
  //---------------------------------------------------------------------------

  public loadCodecs(): Observable<string[]> {
    return this.http.getCodecList().pipe(
      tap((codecs) => {
        this.data.setCodecs(codecs);
      }),
    );
  }

  //---------------------------------------------------------------------------
  // Countries
  //---------------------------------------------------------------------------

  public loadCountries(): Observable<Country[]> {
    return this.http
      .getCountries()
      .pipe(tap((countries) => this.data.setCountries(countries)));
  }

  //---------------------------------------------------------------------------
  // Devices
  //---------------------------------------------------------------------------

  public loadDevices(filter?: {
    [key: string]: string | null;
  }): Observable<DeviceDto[]> {
    return this.http.getDevices(filter).pipe(
      tap((devices) => {
        this.data.setDevices(devices);
        if (filter && Object.values(filter).every((v) => v === '')) {
          this.data.setAllDevices(devices);
        }
      }),
    );
  }

  public loadDevicesFromLocalData(filter: DeviceFilter): void {
    const devices = this.getDevicesByFilters(filter);
    if (devices) {
      this.data.setDevices(devices);
    }
  }

  private getDevicesByFilters(filter: DeviceFilter): DeviceDto[] | undefined {
    let devices = this.data.allDevices;
    if (devices) {
      if (filter.project_id) {
        devices = devices.filter(
          (deviceDto) =>
            deviceDto.tags
              .map((tag) => tag.tag_id)
              .indexOf(filter.project_id as string) > -1,
        );
      }

      if (filter.brand_name) {
        devices = devices.filter(
          (deviceDto) => deviceDto.device_type.brand.name === filter.brand_name,
        );
      }

      if (filter.device_type_id) {
        devices = devices.filter(
          (deviceDto) =>
            deviceDto.device_type.device_type_id === filter.device_type_id,
        );
      }

      if (filter.source) {
        devices = devices.filter(
          (deviceDto) => deviceDto.device_type.source.code === filter.source,
        );
      }
      if (filter.status) {
        devices = devices.filter((deviceDto) =>
          this.checkStatus(deviceDto, filter.status),
        );
      }
    }
    return devices;
  }

  private checkStatus(deviceDto: DeviceDto, status: string): boolean | null {
    let condition: boolean | null = false;

    switch (status) {
      case '':
        condition = true;
        break;
      case '1':
        condition =
          !deviceDto?.statistics?.low_battery &&
          !deviceDto?.statistics?.last_uplink_error &&
          !!deviceDto?.statistics?.last_uplink;
        break;
      case '2':
        condition =
          deviceDto?.statistics?.low_battery &&
          !deviceDto?.statistics?.last_uplink_error &&
          !!deviceDto?.statistics?.last_uplink;
        break;
      case '3':
        condition =
          deviceDto?.statistics?.last_uplink_error &&
          !deviceDto?.statistics?.low_battery &&
          !!deviceDto?.statistics?.last_uplink;
        break;
      case '4':
        condition =
          deviceDto?.statistics?.low_battery &&
          deviceDto?.statistics?.last_uplink_error &&
          !!deviceDto?.statistics?.last_uplink;
        break;
      case '5':
        condition = !deviceDto?.statistics?.last_uplink;
    }
    return condition;
  }

  public updateDevice(deviceInfo: DeviceUpdateInfo): Observable<DeviceDto> {
    return this.http.updateDevice(deviceInfo).pipe(
      switchMap(() => this.loadDevices()),
      map(
        (devices) =>
          devices.find(
            (dev) => dev.device_id === deviceInfo.device_id,
          ) as DeviceDto,
      ),
    );
  }

  public updateDevicePicture(
    deviceId: string,
    devicePicture: DevicePictureInfo,
  ): Observable<DeviceDto> {
    return this.http
      .updateDevicePicture(deviceId, devicePicture)
      .pipe(map((device) => device));
  }

  public updateDeviceAlarm(
    deviceId: string,
    alarmInfo: DeviceAlarm,
  ): Observable<void> {
    return this.http.updateDeviceAlarm(deviceId, alarmInfo);
  }

  //---------------------------------------------------------------------------
  // DeviceMonitoringStats
  //---------------------------------------------------------------------------

  public loadDeviceMonitoringStats(filter: {
    [key: string]: string | null;
  }): Observable<DeviceMonitoringStats> {
    return this.http
      .getMonitoringStats(filter)
      .pipe(tap((stats) => this.data.setDeviceMonitoring(stats)));
  }

  public loadDeviceMonitoringStatsFromLocal(filter: DeviceFilter): void {
    const devicesList = this.getDevicesByFilters(filter);
    if (devicesList) {
      const devicesNumber = devicesList.length;
      let devicesWithLowBattery = 0;
      let devicesWithLastUplinkError = 0;
      let devicesWithLowBatteryLastUplinkError = 0;
      let devicesWithNoMessageYet = 0;
      for (let i = 0; i < devicesNumber; ++i) {
        if (!devicesList[i].statistics.last_uplink) {
          devicesWithNoMessageYet++;
          continue;
        }
        if (
          devicesList[i].statistics.low_battery &&
          devicesList[i].statistics.last_uplink_error
        ) {
          devicesWithLowBatteryLastUplinkError++;
        }
        if (
          devicesList[i].statistics.low_battery &&
          !devicesList[i].statistics.last_uplink_error
        ) {
          devicesWithLowBattery++;
        }
        if (
          !devicesList[i].statistics.low_battery &&
          devicesList[i].statistics.last_uplink_error
        ) {
          devicesWithLastUplinkError++;
        }
      }
      const devicesWithNoWarning =
        devicesNumber -
        (devicesWithLowBattery +
          devicesWithLastUplinkError +
          devicesWithLowBatteryLastUplinkError +
          devicesWithNoMessageYet);

      this.data.setDeviceMonitoring({
        devices: devicesNumber,
        devices_with_no_warning: devicesWithNoWarning,
        devices_with_last_uplink_error: devicesWithLastUplinkError,
        devices_with_low_battery: devicesWithLowBattery,
        devices_with_low_battery_last_uplink_error:
          devicesWithLowBatteryLastUplinkError,
        devices_with_no_message_yet: devicesWithNoMessageYet,
      });
    }
  }

  //---------------------------------------------------------------------------
  // DeviceTypes
  //---------------------------------------------------------------------------

  public loadDeviceTypes(): Observable<DeviceTypeDto[]> {
    return this.http.getDeviceTypes().pipe(
      tap((deviceTypes) => {
        this.data.setDeviceTypes(deviceTypes);
      }),
    );
  }

  public createDeviceType(
    deviceTypeInfo: DeviceTypeInfo,
    imageFile: File | null,
  ): Observable<DeviceTypeDto> {
    return this.http
      .createDeviceType(deviceTypeInfo, imageFile)
      .pipe(tap((newType) => this.data.addDeviceType(newType)));
  }

  public updateDeviceType(
    id: string,
    deviceTypeInfo: DeviceTypeInfo,
    imageFile?: File,
  ): Observable<DeviceTypeDto> {
    return this.http
      .updateDeviceType(id, deviceTypeInfo, imageFile)
      .pipe(tap((newType) => this.data.updateDeviceType(newType)));
  }

  public updateDeviceTypeCodec(
    id: string,
    decodingInfo: DecoderBluePrint,
  ): Observable<DeviceTypeDto> {
    return this.http
      .updateDeviceTypeCodec(id, decodingInfo)
      .pipe(tap((newType) => this.data.updateDeviceType(newType)));
  }

  public changeDeviceTypeActiveState(
    typeId: string,
    state: boolean,
  ): Observable<boolean> {
    return this.http.changeDeviceTypeActiveState(typeId, state).pipe(
      tap(() => this.data.changeDeviceTypeActiveState(typeId, state)),
      map(() => state),
    );
  }

  //---------------------------------------------------------------------------
  // Gateways
  //---------------------------------------------------------------------------

  public loadGateways(): Observable<Gateway[]> {
    return this.http.getGateways().pipe(tap((gw) => this.data.setGateways(gw)));
  }

  //---------------------------------------------------------------------------
  // GatewayRssi
  //---------------------------------------------------------------------------

  public loadGatewayRssi(gatewayId: string): Observable<RssiHistoryData[]> {
    return this.http
      .getGatewayRssi(gatewayId)
      .pipe(tap((rssi) => this.data.setGatewayRssi(gatewayId, rssi)));
  }

  //---------------------------------------------------------------------------
  // Interfaces
  //---------------------------------------------------------------------------

  public loadInterfaces(): Observable<Interface[]> {
    return this.http
      .getRoutingInterfaces()
      .pipe(tap((interfaces) => this.data.setInterfaces(interfaces)));
  }

  public createInterface(interf: Interface): Observable<Interface> {
    return this.http
      .createInterface(interf)
      .pipe(tap((created) => this.data.addInterface(created)));
  }

  public updateInterface(interf: Interface): Observable<Interface> {
    return this.http.updateInterface(interf).pipe(
      tap((updated) => {
        this.data.updateInterface(updated);
      }),
    );
  }

  //---------------------------------------------------------------------------
  // Organizations
  //---------------------------------------------------------------------------

  public loadOrganizations(): Observable<Organization[]> {
    return this.http
      .getOrganizations()
      .pipe(tap((countries) => this.data.setOrganizations(countries)));
  }

  //---------------------------------------------------------------------------
  // Routings
  //---------------------------------------------------------------------------

  public loadRoutings(): Observable<RouteDto[]> {
    return this.http.getRoutings().pipe(
      tap((routings) => {
        this.data.setRoutings(routings);
      }),
    );
  }

  public loadRouting(id: string): Observable<RouteDto> {
    return this.http.getRouteById(id).pipe(
      tap((routing) => {
        this.data.setRouting(routing);
      }),
    );
  }

  public createExportRoute(
    dest: string,
    routeInfo: { info: GeneralRouteInfo; output: string },
  ): void {
    this.http
      .createRouting(dest, routeInfo)
      .pipe(
        tap(() =>
          this.snackBar.open('Route was successfully created!', 'Close'),
        ),
        switchMap(() => this.loadRoutings()),
      )
      .subscribe(() => {
        this.router.navigateByUrl(`/home/routing`);
      });
  }

  public updateExportRoute(
    routeID: string,
    dest: string,
    routeInfo: { info: GeneralRouteInfo; output: string },
  ): void {
    this.http
      .updateRouting(routeID, dest, routeInfo)
      .pipe(
        tap(() =>
          this.snackBar.open('Route was successfully updated!', 'Close'),
        ),
        switchMap(() => this.loadRoutings()),
      )
      .subscribe(() => {
        this.router.navigateByUrl(`/home/routing`);
      });
  }

  public changeRouteActiveState(
    routeId: string,
    state: boolean,
  ): Observable<RouteDto> {
    return this.http.changeRouteActiveState(routeId, state).pipe(
      tap(() =>
        this.snackBar.open('Route state was successfully updated!', 'Close'),
      ),
      switchMap(() => this.http.getRouteById(routeId)),
      tap((route) => this.data.updateRoute(routeId, route)),
    );
  }

  public updateLockRouting(
    routeId: string,
    is_locked: boolean,
  ): Observable<RouteDto> {
    const lockedText = is_locked ? 'locked' : 'unlocked';
    return this.http.updateLockRouting(routeId, is_locked).pipe(
      tap(() =>
        this.snackBar.open(`Route was successfully ${lockedText}!`, 'Close'),
      ),
      switchMap(() => this.http.getRouteById(routeId)),
      tap((route) => this.data.updateRoute(routeId, route)),
    );
  }

  //---------------------------------------------------------------------------
  // Sites
  //---------------------------------------------------------------------------

  public loadSites(): Observable<Site[]> {
    return this.http.getSites().pipe(map((sites) => this.data.setSites(sites)));
  }

  public createSite(site: CreateSiteDTO): Observable<Site> {
    return this.http.createSite(site).pipe(
      tap((s) => {
        this.data.addSite(s);
      }),
    );
  }

  public updateSite(code: string, site: CreateSiteDTO): Observable<Site> {
    return this.http.updateSite(code, site).pipe(
      tap((update) => {
        this.data.updateSite(update);
      }),
    );
  }

  public updateSiteAlert(
    site: string,
    alertConfig: SiteNotificationConfig,
  ): Observable<Site[]> {
    return this.http.updateSiteAlert(site, alertConfig).pipe(
      switchMap(() => this.loadSites()),
      tap(() => {
        this.snackBar.open('Site alert was successfully edited!', 'Close');
      }),
    );
  }

  //---------------------------------------------------------------------------
  // Sources
  //---------------------------------------------------------------------------

  public loadSources(): Observable<Source[]> {
    return this.http
      .getSources()
      .pipe(tap((sources) => this.data.setSources(sources)));
  }

  //---------------------------------------------------------------------------
  // Users
  //---------------------------------------------------------------------------

  public loadUsers(): Observable<{ items: User[]; total: number }> {
    return this.http.getUsers().pipe(
      tap((users) => {
        this.data.setUsers(users.items);
      }),
    );
  }

  public createUser(userInfo: UserInfo): Observable<User> {
    return this.http.createUser(userInfo).pipe(
      tap((user) => this.data.addUser(user)),
      tap(() => {
        this.snackBar.open('User was successfully created!', 'Close');
      }),
    );
  }

  public updateUser(userInfo: UserInfo): Observable<User> {
    return this.http.updateUser(userInfo).pipe(
      tap((user) => this.data.updateUser(user)),
      tap(() => {
        this.snackBar.open('User was successfully edited!', 'Close');
      }),
    );
  }

  public updateUserAlert(
    id: string,
    alert: UserAlertInfo,
    site: string,
  ): Observable<User> {
    return this.http.updateUserAlarm(id, alert, site).pipe(
      tap((user) => this.data.updateUser(user)),
      tap(() => {
        this.snackBar.open('User was successfully edited!', 'Close');
      }),
    );
  }

  public deleteUser(email: string): Observable<void> {
    return this.http.deleteUser(email).pipe(
      tap(() => this.data.deleteUser(email)),
      tap(() => {
        this.snackBar.open('User was successfully deleted!', 'Close');
      }),
    );
  }

  //---------------------------------------------------------------------------
  // Downlinks
  //---------------------------------------------------------------------------

  public loadDeviceDownlinks(deviceId: string): Observable<Downlink[]> {
    return this.http.getDeviceDownlinks(deviceId).pipe(
      map((downlinks) => downlinks.sort((a, b) => b.timestamp - a.timestamp)),
      tap((downlinks) => {
        this.data.setDeviceDownlinks(downlinks);
      }),
    );
  }
}
