import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { User } from 'src/models/user.models';
import { DataLoaderService } from '../../../services/state/data/data-loader.service';
import { AuthService } from './../../../services/state/auth/auth.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
})
export class UserManagementComponent {
  screenSizeIndex$ = this.interfaceState.breakpointIndex$;
  filteredUsers$ = this.data.users$;
  users$ = this.data.users$;
  sites$ = this.data.sites$;

  constructor(
    private readonly data: DataStoreService,
    private readonly interfaceState: InterfaceStateService,
    private readonly router: Router,
    private readonly dataLoader: DataLoaderService,
    public auth: AuthService,
  ) {
    this._loadData();
  }

  private _loadData() {
    combineLatest([
      this.data.users$.pipe(
        switchMap((users) => (users ? of(users) : this.dataLoader.loadUsers())),
      ),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
      ),
    ])
      .pipe(first())
      .subscribe();
  }

  public updateFilteredUsers(users: User[]): void {
    this.filteredUsers$ = of(users);
  }

  public hasMultipleSiteAccess(user: User): boolean {
    return user.administrator || user.roles.length > 1;
  }

  public getHighestPermission(user: User): string {
    if (user.technicalAdministrator) {
      return 'Technical Administrator';
    }
    if (user.administrator) {
      return 'Administrator';
    }
    if (user.roles.map((role) => role.role).includes('maintainer')) {
      return 'Maintainer';
    }
    if (user.roles.map((role) => role.role).includes('operator')) {
      return 'Operator';
    }
    return 'None';
  }

  public getUserSiteLabel(user: User): string {
    const userSiteIds = user.roles.map((role) => role.siteId);
    const userSite = this.data.sites?.find(
      (site) => site.id === userSiteIds[0],
    );
    if (this.hasMultipleSiteAccess(user)) {
      return 'Multiple';
    }
    if (!userSiteIds.length) {
      return 'None';
    }
    return userSite
      ? `${userSite?.country.isoCode} - ${userSite?.name}`
      : userSiteIds[0];
  }

  public editUser(user: User): void {
    this.router.navigateByUrl('home/users/edit', { state: user });
  }
}
