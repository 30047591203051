<div class="home">
  <mat-toolbar class="toolbar mat-elevation-z5">
    <button mat-icon-button class="menu-btn" (click)="navbar.toggle()">
      <mat-icon *ngIf="!navbar.opened">menu</mat-icon>
      <mat-icon *ngIf="navbar.opened">close</mat-icon>
    </button>
    <span>{{ title$ | async }}</span>
    <div class="spacer"></div>
    <button
      mat-icon-button
      (click)="onLogout()"
      matTooltipPosition="before"
      matTooltip="logout"
    >
      <mat-icon>person</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container class="content">
    <mat-sidenav
      mode="side"
      opened
      fixedInViewport="false"
      class="sidenav"
      #navbar
    >
      <mat-nav-list>
        <mat-list-item
          [routerLink]="['../', 'home', 'dashboard']"
          [class.active]="isActive('/home/dashboard')"
        >
          <mat-icon matListItemIcon>equalizer</mat-icon>
          <div matListItemTitle>Home</div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="['device', deviceManagement.view]"
          [queryParams]="
            deviceManagement.path ? { path: deviceManagement.path } : ''
          "
          [class.active]="isActive('/home/device')"
        >
          <mat-icon matListItemIcon>tune</mat-icon>

          <div matListItemTitle>Device Management</div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="['gateways']"
          [class.active]="isActive('/home/gateways')"
        >
          <mat-icon matListItemIcon>router</mat-icon>

          <div matListItemTitle>Gateways</div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="['catalogue']"
          [class.active]="isActive('/home/catalogue')"
        >
          <mat-icon matListItemIcon svgIcon="custom-catalogue"></mat-icon>

          <div matListItemTitle>Catalogue</div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="['routing']"
          [class.active]="isActive('/home/routing')"
        >
          <mat-icon matListItemIcon>linear_scale</mat-icon>

          <div matListItemTitle>Routing</div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="['site']"
          [class.active]="isActive('/home/site')"
          *ngIf="auth.isTechnicalAdmin()"
        >
          <mat-icon matListItemIcon>business</mat-icon>

          <div matListItemTitle>Site Management</div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="['interfaces']"
          [class.active]="isActive('/home/interfaces')"
          *ngIf="auth.isTechnicalAdmin()"
        >
          <mat-icon matListItemIcon>alt_route</mat-icon>

          <div matListItemTitle>Interface Management</div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="['users']"
          [class.active]="isActive('/home/users')"
        >
          <mat-icon matListItemIcon>supervised_user_circle</mat-icon>

          <div matListItemTitle>User Management</div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="['alerts']"
          [class.active]="isActive('/home/alerts')"
        >
          <mat-icon matListItemIcon>notifications</mat-icon>

          <div matListItemTitle>Alerts</div>
        </mat-list-item>
        <mat-list-item
          [routerLink]="['redrive']"
          [class.active]="isActive('/home/redrive')"
          *ngIf="auth.isTechnicalAdmin()"
        >
          <mat-icon matListItemIcon>replay</mat-icon>

          <div matListItemTitle>Redrive Messages</div>
        </mat-list-item>
        <mat-list-item [attr.href]="supportUrl" target="_blank">
          <mat-icon matListItemIcon>contact_support</mat-icon>
          <div matListItemTitle>Support</div>
        </mat-list-item>
      </mat-nav-list>
      <div class="spacer"></div>
      <img
        src="assets/svg/logo-transparent-with-text.svg"
        alt="Solvay logo"
        class="logo logo-sidenav"
      />
      <p class="version">{{ version }}</p>
    </mat-sidenav>

    <mat-sidenav-content>
      <div id="bg1"></div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
