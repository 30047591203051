import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { DevicesService } from 'src/app/services/http/devices.service';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceTypeDto, DeviceTypeInfo } from 'src/models/device-type.models';
import { ModelCreationComponent } from '../model-creation/model-creation.component';
import { ParserCreationService } from './parser-tab/parser-creation.service';

@Component({
  selector: 'app-model-detail',
  templateUrl: './model-detail.component.html',
  styleUrls: ['./model-detail.component.scss'],
})
export class ModelDetailComponent {
  model: DeviceTypeDto;
  noInfoMsg = 'No information has been entered yet.';
  constructor(
    private route: ActivatedRoute,
    private deviceHttp: DevicesService,
    private data: DataStoreService,
    public auth: AuthService,
    public dialog: MatDialog,
    public router: Router,
    public dataLoader: DataLoaderService,
    public parserCreation: ParserCreationService,
  ) {
    this._loadData();
  }

  _loadData() {
    if (this.route.snapshot.paramMap.get('id')) {
      this.deviceHttp
        .getDeviceTypeByID(
          this.route.snapshot.paramMap.get('id') as string,
          true,
        )
        .pipe(
          first(),
          filter((v) => !!v),
        )
        .subscribe((deviceType) => {
          if (deviceType.decoder_blueprint) {
            this.parserCreation.setBlueprint(deviceType.decoder_blueprint);
          } else {
            this.parserCreation.resetBluePrints();
          }
          this.model = deviceType;
        });
    }
  }

  public onEdit() {
    this.dialog
      .open(ModelCreationComponent, { data: this.model })
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        first(),
        switchMap((res) =>
          this.dataLoader.updateDeviceType(
            this.model.device_type_id,
            res.info as DeviceTypeInfo,
            res.image,
          ),
        ),
        tap((updatedModel) => {
          this.model = updatedModel;
        }),
      )
      .subscribe();
  }

  delete() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          html: `You are about to delete this device type. Do you want to continue?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((v) => !!v),
        switchMap(() =>
          this.deviceHttp.deleteDeviceType(this.model.device_type_id),
        ),
        tap(() => this.data.deleteDeviceType(this.model.device_type_id)),
        tap(() => this.router.navigateByUrl('/home/catalogue')),
      )
      .subscribe();
  }

  toggleDisable() {
    this.dataLoader
      .changeDeviceTypeActiveState(
        this.model.device_type_id,
        !this.model.disabled,
      )
      .subscribe((disabled) => (this.model.disabled = disabled));
  }

  close() {
    this.router.navigate(['../..'], {
      relativeTo: this.route,
    });
  }
}
