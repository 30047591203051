export const environment = {
  production: false,
  apiUrlConfigs: 'https://iothub-test.solvay.com/api/v1/configuration/',
  apiUrlCommands: 'https://iothub-test.solvay.com/api/v1/command/',
  firebaseConfig: {
    apiKey: 'AIzaSyCElpyJhAHcSJc1tkH_9EEHG_ZLxnT6BZk',
    authDomain: 'prj-iothub-test.firebaseapp.com',
    databaseURL: 'https://prj-iothub-test.firebaseio.com',
    projectId: 'prj-iothub-test',
    storageBucket: 'prj-iothub-test.appspot.com',
    messagingSenderId: '27579843441',
    appId: '1:27579843441:web:d1cd37bcfacc5c53ef6cd0',
  },
  googleSheetBaseUrl: 'https://docs.google.com/spreadsheets/d/',
  grafanaUrl: 'https://35.205.95.115/',
  supportGoogleSheetUrl:
    'https://docs.google.com/spreadsheets/d/11pN9PYlnGZssPa3mJf9vFpoRcuWNfT8JQURaZ-JsBv8/edit#gid=0',
  gsExporterServiceAccount: 'prj-iothub-test@appspot.gserviceaccount.com',
  openStreetMap: {
    defaultMapCenter: {
      lat: 50.901072,
      lng: 4.400331,
    },
    layerTilesUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  serviceAccountGoogleSheet: 'prj-iothub-test@appspot.gserviceaccount.com',
  stage: 'test',
};
