import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, merge, of, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { GatewayStats } from '../../../../models/gateway.models';
import { GatewaysService } from '../../../services/http/gateways.service';

const GATEWAY_POLLING_TIME = 600000; // 10min

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public screensizeIndex$ = this.interfaceState.breakpointIndex$;
  public monitoringStats$ = this.data.deviceMonitoring$;
  public displayedDevices$ = this.data.devices$;
  public models$ = this.data.deviceTypes$;
  public brands$ = this.data.brands$;
  public sites$ = this.data.sites$;
  public sources$ = this.data.sources$;
  public filterForm: UntypedFormGroup;

  private gatewayStats$ = new BehaviorSubject<GatewayStats | undefined>(
    undefined,
  );
  private isLoadingGateways$ = new BehaviorSubject(true);

  constructor(
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    private interfaceState: InterfaceStateService,
    private gatewaysService: GatewaysService,
    public dialog: MatDialog,
  ) {
    this._loadData();
  }

  ngOnInit() {
    this.filterForm = this.interfaceState.dashboardFilter;

    this.filterForm.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => this.interfaceState.setDashboardFilter(this.filterForm)),
      )
      .subscribe((filter) => {
        this.dataLoader.loadDevicesFromLocalData(filter);
        this.dataLoader.loadDeviceMonitoringStatsFromLocal(filter);
        this.loadGatewaysStats();
      });

    timer(0, GATEWAY_POLLING_TIME)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadGatewaysStats();
      });
  }

  public openMap(templateRef: TemplateRef<unknown>) {
    this.dialog.open(templateRef);
  }

  private _loadData() {
    if (this.interfaceState.dashboardFilter) {
      this.interfaceState.dashboardFilter.get('status')?.setValue('');
    }

    merge(
      this.dataLoader.loadDevices(this.interfaceState.dashboardFilter.value),
      this.dataLoader.loadDeviceMonitoringStats(
        this.interfaceState.dashboardFilter.value,
      ),
      this.data.deviceTypes$.pipe(
        untilDestroyed(this),
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadDeviceTypes(),
        ),
        catchError(() => of([])),
      ),
      this.data.brands$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadBrands(),
        ),
        catchError(() => of([])),
      ),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
        catchError(() => of([])),
      ),
      this.data.sources$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadSources(),
        ),
        catchError(() => of([])),
      ),
    ).subscribe();
  }

  private loadGatewaysStats() {
    this.isLoadingGateways$.next(true);
    combineLatest([
      this.gatewaysService.getList({
        limit: 0,
        siteId: this.filterForm.value.siteId || undefined,
      }),
      this.gatewaysService.getList({
        limit: 0,
        // 4 hours before now
        lastCheckinAfter: new Date(Date.now() - 14400000),
        siteId: this.filterForm.value.siteId || undefined,
      }),
    ]).subscribe(([responseAll, responseActive]) => {
      this.gatewayStats$.next({
        active: responseActive.total,
        inactive: responseAll.total - responseActive.total,
      });
      this.isLoadingGateways$.next(false);
    });
  }
}
