import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import Chart from 'chart.js';
import { first, tap } from 'rxjs/operators';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { RssiHistoryData } from 'src/models/gateway.models';

@Component({
  selector: 'app-gateway-data-visual',
  templateUrl: './gateway-data-visual.component.html',
  styleUrls: ['./gateway-data-visual.component.scss'],
})
export class GatewayDataVisualComponent implements OnInit {
  public chart: Chart;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { gw: string },
    private ref: MatDialogRef<unknown>,
    private store: DataStoreService,
    private dataLoader: DataLoaderService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    const rssi = this.store.gatewayRssi[this.data.gw];
    if (rssi) {
      this.formatGraphData(rssi);
    } else
      this.dataLoader
        .loadGatewayRssi(this.data.gw)
        .pipe(
          first(),
          tap((res) => this.formatGraphData(res)),
        )
        .subscribe();
  }

  onClose() {
    this.ref.close();
  }

  formatGraphData(rssi: RssiHistoryData[]) {
    const data = {
      labels: rssi.map(
        (data) =>
          `${data.timestamp.slice(0, 10)}  ${data.timestamp.slice(11, 16)}`,
      ),
      datasets: [
        {
          label: 'RSSI signal strength',
          data: rssi.map((data) => data.rssi),
          fill: false,
          borderColor: '#0077e3',
        },
      ],
    };

    this.chart = new Chart(document.getElementById('gatewayDataChart'), {
      type: 'line',
      data: data,
    });
  }
}
