<div mat-dialog-content>
  <h1>MQTT Topic Patterns</h1>
  <p>
    Topic patterns are used to classify and filter the devices registered the
    IOT-Hub and the messages they are sending. To achieve the goal of selecting
    device data for routing, users need to follow and understand MQTT topic
    patterns.
  </p>
  <h2>Topic Structures</h2>
  <img
    src="https://storage.googleapis.com/solvay-iot-platform-dev-assets/mqtt_topics_example.png"
    alt="topic-example"
  />
  <p>
    This is how a typical topic would look like on IOT-Hub. As you can see, the
    topic has different levels and each level is seperated by “/”. On IOT-Hub,
    all topics start with the production site followed by the topic levels
    specified during the device creation process.
  </p>
  <p>
    A topic may have many different levels for organizing devices and their
    messages. As you can see in the sample topic above, you can set different
    levels for better organization of device messages. You can treat mqtt topics
    as channels for communication.
  </p>
  <h2>Topic Level Wildcard</h2>
  <p>
    You can use wildcard symbols to represent all topics at specific levels.
  </p>
  <h3>Single Level Using +</h3>
  <p>
    You can use the plus sign in the topic to represent a wildcard for one
    level. In this case, any topic with arbitrary string would match the level
    that contains the plus sign. For example,
    <span>site_passadena/bu/+/temperature</span> would match the following:
  </p>
  <ul>
    <li><span>site_passadena/bu/sensor_1/temperature</span></li>
    <li><span>site_passadena/bu/sensor_2/temperature</span></li>
  </ul>
  <p>but not the following:</p>
  <ul>
    <li><span>site_passadena/bu/temperature</span></li>
    <li><span>site_passadena/bu/sensor_2/humidity</span></li>
  </ul>
  <h3>Multi Levels Using #</h3>
  <p>
    You can use the hashtag to represent multi levels. In this case, any topic
    with arbitrary string in arbitrary levels would match the level that
    contains the hashtag sign. Note that the hashtag symbol can only be
    presented at the end of a topic string. For example,
    <span>site_passadena/bu/project_1/#</span>
    would match the following:
  </p>
  <ul>
    <li><span>site_passadena/bu/project_1/sensor_1/temperature</span></li>
    <li><span>site_passadena/bu/project_1/sensor_3/humidity</span></li>
    <li>
      <span>site_passadena/bu/project_1/subfolder/sensor_25/light</span>
    </li>
  </ul>
  <p>but not the following:</p>
  <ul>
    <li><span>site_passadena/bu/project_3/sensor_1/temperature</span></li>
    <li><span>site_passadena/bu/sensor_3/humidity</span></li>
  </ul>
</div>
<div mat-dialog-actions class="actions">
  <button
    mat-flat-button
    mat-dialog-close
    cdkFocusInitial
    color="primary"
  >
    Close
  </button>
</div>
