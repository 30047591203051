import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MQTTTopicHelpDialogComponent } from './../mqtt-topic-help-dialog/mqtt-topic-help-dialog.component';

@Component({
  selector: 'app-pattern-builder-help',
  templateUrl: './pattern-builder-help.component.html',
  styleUrls: ['./pattern-builder-help.component.scss'],
})
export class PatternBuilderHelpComponent {
  constructor(private dialog: MatDialog) {}

  public openMqttHelp(): void {
    this.dialog.open(MQTTTopicHelpDialogComponent);
  }
}
