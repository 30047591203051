import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatTableModule,
    MatDialogModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDividerModule,
    LayoutModule,
    MatListModule,
    MatRadioModule,
    MatTreeModule,

    MatTabsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatCardModule,
    MatSortModule,
    MatProgressBarModule,
  ],
  exports: [
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatTableModule,
    MatDialogModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDividerModule,
    LayoutModule,
    MatListModule,
    MatRadioModule,
    MatTreeModule,

    MatTabsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatCardModule,
    MatSortModule,
    MatProgressBarModule,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
  ],
})
export class MaterialModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      'custom-solvay-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/logo-solvay-white.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-routing-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/route_active.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-routing-paused',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/route_paused.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/info.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-infos',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/Infos.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-catalogue',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/catalogue.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-add-folder',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/add-folder.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-add-device',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/add-device.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-add-devices',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/add-devices.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-route-btn',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/route-btn.svg',
      ),
    );
  }
}
