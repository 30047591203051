<div class="list-management">
  <section class="filter-container mat-elevation-z4" *appLoader="sites$">
    <div *ngIf="(screenSizeIndex$ | async) >= 2">
      <ng-template *ngTemplateOutlet="formTpl"></ng-template>
    </div>
    <mat-expansion-panel
      *ngIf="(screenSizeIndex$ | async) < 2"
      class="mat-elevation-z0"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Filter </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template *ngTemplateOutlet="formTpl"></ng-template>
    </mat-expansion-panel>
  </section>
  <section class="list" *appLoader="users$">
    <div class="list-header mat-elevation-z4">
      <div class="list-row">
        <div class="list-col">Contact Email</div>
        <div class="list-col">Permission Level</div>
        <div class="list-col">Site</div>
        <div class="list-col">Status</div>
      </div>
    </div>
    <div class="list-body">
      <div
        class="list-row clickable"
        [ngClass]="{ 'blue-bg': user.active, 'light-blue-bg': !user.active }"
        *ngFor="let user of filteredUsers$ | async"
        (click)="editUser(user)"
      >
        <div class="list-col">{{ user.email }}</div>
        <div class="list-col">{{ getHighestPermission(user) }}</div>
        <div class="list-col">
          {{ getUserSiteLabel(user) }}
        </div>
        <div class="list-col">
          {{ user.active ? 'active' : 'blocked' }}
        </div>
      </div>
    </div>
  </section>
  <button
    mat-fab
    color="primary"
    class="floating-button"
    [routerLink]="['new']"
    *ngIf="auth.isTechnicalAdmin()"
    matTooltipPosition="below"
    matTooltip="add a new user"
  >
    <mat-icon>person_add</mat-icon>
  </button>

  <ng-template #formTpl>
    <app-user-filter
      [sites]="sites$ | async"
      [users]="users$ | async"
      (usersFiltered)="updateFilteredUsers($event)"
    ></app-user-filter>
  </ng-template>
</div>
