import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceAlarm, DeviceDto } from 'src/models/device.models';
import { Site } from 'src/models/site.models';
import { User } from 'src/models/user.models';
import { AlertingService } from './alerting.service';

@Component({
  selector: 'app-alerting',
  templateUrl: './alerting.component.html',
  styleUrls: ['./alerting.component.scss'],
})
export class AlertingComponent implements OnInit {
  public sites$ = this.data.sites$;
  public models$ = this.data.deviceTypes$;
  public brands$ = this.data.brands$;
  public sources$ = this.data.sources$;
  public siteSelection = new UntypedFormControl(this.alerting.selectedSite);
  public userEmailSearch = new UntypedFormControl('');
  public deviceFilterForm: UntypedFormGroup;
  public devices: DeviceDto[];
  public users: User[];

  constructor(
    private readonly data: DataStoreService,
    private readonly dataLoader: DataLoaderService,
    public alerting: AlertingService,
    private readonly auth: AuthService,
  ) {
    this._loadData();
  }

  ngOnInit(): void {
    combineLatest([
      this.data.devices$,
      this.data.users$,
      this.siteSelection.valueChanges.pipe(
        startWith(this.siteSelection.value),
        tap(() => this.resetFilters()),
      ),
    ])
      .pipe(
        tap(([_devices, _users, site]) => {
          this.alerting.setSelectedSite(site);
          this.devices = this.getDevicesForSelectedSite();
          this.users = this.getUsersForSite(site);
        }),
      )
      .subscribe();

    this.deviceFilterForm = this.alerting.deviceFilter;

    this.deviceFilterForm.valueChanges
      .pipe(
        tap(() => this.alerting.setDeviceFilter(this.deviceFilterForm)),
        switchMap((filter) => this.dataLoader.loadDevices(filter)),
        tap(() => (this.devices = this.getDevicesForSelectedSite())),
      )
      .subscribe();

    this.userEmailSearch.valueChanges
      .pipe(
        map((searchValue: string) =>
          this.getUsersForSite(this.siteSelection.value).filter((user) =>
            user.email.includes(searchValue),
          ),
        ),
        tap((users) => (this.users = users)),
      )
      .subscribe();
  }

  private _loadData() {
    combineLatest([
      this.dataLoader.loadDevices(),
      this.data.deviceTypes$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadDeviceTypes(),
        ),
        catchError(() => of([])),
      ),
      this.data.brands$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadBrands(),
        ),
        catchError(() => of([])),
      ),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
        catchError(() => of([])),
      ),
      this.data.sources$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadSources(),
        ),
        catchError(() => of([])),
      ),
      this.data.users$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadUsers())),
        catchError(() => of([])),
      ),
    ])
      .pipe(first())
      .subscribe();
  }

  private getDevicesForSelectedSite(): DeviceDto[] {
    return this.data.devices && this.site
      ? this.data.devices.filter(
          (device) =>
            !!device?.tags.filter((tag) => tag.tag_id === this.site.code)
              .length,
        )
      : [];
  }

  private getUsersForSite(siteId: string): User[] {
    return this.data.users.filter((user) =>
      user.roles.map((role) => role.siteId).includes(siteId),
    );
  }

  private resetFilters(): void {
    this.alerting.resetDeviceFilter();
    if (this.deviceFilterForm)
      this.deviceFilterForm.patchValue(this.alerting.deviceFilter.value);
    this.userEmailSearch.setValue('');
  }

  public get showSiteSelectionPrompt(): boolean {
    return !(
      !this.alerting.selectedSite && this.auth.userHasMultipleSiteAccess
    );
  }

  public toggleConnectionAlert(device: DeviceDto): void {
    const alarm = device.alarm;
    alarm.connection.enable = !alarm.connection.enable;
    this.updateDeviceAlarm(device.device_id, alarm);
  }

  public toggleBatteryAlert(device: DeviceDto): void {
    const alarm = device.alarm;
    alarm.battery.enable = !alarm.battery.enable;
    this.updateDeviceAlarm(device.device_id, alarm);
  }

  public updateConnectionAlertDelay(event: Event, device: DeviceDto): void {
    const alarm = device.alarm;
    const target = event.target as HTMLInputElement;
    alarm.connection.delay = parseInt(target.value);
    this.updateDeviceAlarm(device.device_id, alarm);
  }

  public updateDeviceAlarm(deviceId: string, alarmInfo: DeviceAlarm): void {
    this.dataLoader.updateDeviceAlarm(deviceId, alarmInfo).subscribe();
  }

  public toggleUserAlert(user: User): void {
    const siteId = this.siteSelection.value;
    const alertInfo = {
      enable: !this.userAlertForSite(user),
    };
    this.dataLoader.updateUserAlert(user.email, alertInfo, siteId).subscribe();
  }

  public get userHasUpdateRight() {
    const siteId = this.siteSelection.value;
    return this.auth.isAdmin || this.auth.isMaintainerOnSite(siteId);
  }

  public userAlertForSite(user: User) {
    const siteId = this.siteSelection.value;
    return user.alerts.find((alert) => alert.siteId === siteId)?.alert;
  }

  public setDevicePanelOpenState(state: boolean): void {
    this.alerting.setDevicesOpen(state);
  }

  public setUserPanelOpenState(state: boolean): void {
    this.alerting.setUserOpen(state);
  }

  public get site(): Site {
    const site = this.data.sites?.find(
      (site) => site.id === this.alerting.selectedSite,
    );
    return site as Site;
  }

  public toggleSiteAlert() {
    const site = this.site;
    const alert = this.site.alertEnabled;
    const siteAlertConf = {
      cron: '0 12 * * *',
      enable: !alert,
      timezone: 'Europe/Paris',
    };
    this.dataLoader.updateSiteAlert(site.code, siteAlertConf).subscribe();
  }
}
