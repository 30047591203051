import { Component, QueryList, ViewChildren, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ResolveFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { DeviceManagementService } from 'src/app/pages/main/device-management/device-management.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceHierarchyStoreService } from 'src/app/services/state/data/device-hierarchy/device-hierarchy-store.service';
import { HierarchyNode } from 'src/models/device-hierarchy.models';
import { DeviceDto } from 'src/models/device.models';
import { AuthService } from '../../../../services/state/auth/auth.service';
import { DataLoaderService } from '../../../../services/state/data/data-loader.service';
import { DeviceCreationService } from '../device-creation/device-creation.service';
import { DeviceProvidingComponent } from '../device-providing/device-providing.component';
import { DeviceListElementComponent } from './device-list-element/device-list-element.component';
import { NewFolderDialogComponent } from './new-folder-dialog/new-folder-dialog.component';

@Component({
  selector: 'app-hierarchy-node-list',
  templateUrl: './hierarchy-node-list.component.html',
  styleUrls: ['./hierarchy-node-list.component.scss'],
})
export class HierarchyNodeListComponent {
  folders$ = this.foldersInSelectedNode$;
  devices$ = this.devicesInSelectedNode$;

  allDevices$ = this.data.devices$;

  get hasDragData() {
    return this.deviceManagement.hasDragData();
  }

  @ViewChildren('deviceEls') deviceEls: QueryList<DeviceListElementComponent>;

  static getResolver(): Record<string, ResolveFn<unknown>> {
    return {
      sites: () =>
        inject(DeviceManagementService).userHasWriteRights()
          ? inject(DataStoreService).sites ??
            inject(DataLoaderService).loadSites()
          : undefined,
    };
  }

  constructor(
    private deviceManagement: DeviceManagementService,
    private deviceHierarchy: DeviceHierarchyStoreService,
    private data: DataStoreService,
    public router: Router,
    public dialog: MatDialog,
    public auth: AuthService,
    private dataLoader: DataLoaderService,
    private snack: MatSnackBar,
    private deviceCreation: DeviceCreationService,
  ) {
    this._loadData();
  }

  private _loadData() {
    this.dataLoader.loadDevices().pipe(first()).subscribe();
  }

  resetDragData() {
    this.deviceManagement.resetDragData();
    for (const el of this.deviceEls?.toArray() ?? []) el.toggleCheckbox(false);
  }

  private get foldersInSelectedNode$(): Observable<HierarchyNode[]> {
    return this.deviceManagement.selectedNode$.pipe(
      filter((node) => !!node),
      map((node: HierarchyNode) =>
        this.deviceHierarchy.getSortedFolders(node.children),
      ),
      tap(() => this.resetDragData()),
    );
  }

  private get devicesInSelectedNode$(): Observable<DeviceDto[]> {
    return this.deviceManagement.selectedNode$.pipe(
      map((node) => this.deviceHierarchy.getSortedDevices(node.children)),
      map((deviceNodes) =>
        deviceNodes
          .map(
            (node) =>
              this.data.devices?.find(
                (device) => device.device_id === node.id,
              ) as DeviceDto,
          )
          .filter((v) => !!v),
      ),
    );
  }

  public get actionButtonsEnabled(): boolean {
    return (
      this.deviceManagement.userHasWriteRights() &&
      this.deviceManagement.selectedNode.id !== 'root'
    );
  }

  public folderSelected(folder: HierarchyNode): void {
    const newPath = `${this.deviceManagement.path}/${folder.id}`;
    this.router.navigate([], { queryParams: { path: newPath } });
  }

  public deviceSelected(device: DeviceDto): void {
    this.router.navigateByUrl(`home/device/details/${device.device_id}`);
  }

  public addFolder(): void {
    const newFolder = {
      id: '',
      name: '',
      children: [],
      isDevice: false,
      isData: false,
    };

    const dialogRef = this.dialog.open(NewFolderDialogComponent);

    dialogRef
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        tap((res) => {
          newFolder.name = res;
          newFolder.id = res;
        }),
        tap(() => this.deviceManagement.addFolder(newFolder)),
      )
      .subscribe();
  }

  public addDevice(): void {
    this.router.navigate(['/home/device/new'], {
      queryParams: { path: this.deviceManagement.path },
    });
  }

  addDevices(): void {
    if (!this.deviceManagement.isFireflyConfiguredInSelectedSite()) {
      this.snack.open(
        'Operation impossible: the current site misses a configured Firefly organization.',
        'Close',
      );
      return;
    }
    this.data.deviceTypes$
      .pipe(
        first(),
        switchMap((types) =>
          types?.length ? of(types) : this.dataLoader.loadDeviceTypes(),
        ),
        map(() =>
          this.dialog.open(DeviceProvidingComponent, {
            autoFocus: false,
          }),
        ),
        tap((ref) => ref.backdropClick().subscribe(() => ref.close())),
        switchMap((ref) => ref.afterClosed()),
      )
      .subscribe((res) => {
        if (res) this.snack.open('Devices added successfully', 'OK');
      });
  }

  endDrag(event: DragEvent, node: HierarchyNode) {
    this.deviceManagement
      .dropDraggedItemOnNode(event, node)
      ?.pipe(
        switchMap((deviceIds) =>
          this.deviceCreation.moveDevices(
            deviceIds,
            this.deviceHierarchy.getPathForNode(node),
          ),
        ),
        switchMap(() => this.deviceHierarchy.loadDeviceHierarchy()),
      )
      .subscribe(() => {
        this.deviceManagement.resetDragData();
        this.deviceManagement.setSelectedNode(
          this.deviceHierarchy.getSelectedNodeForPath(
            this.deviceManagement.path,
          ),
        );
        this.snack.open('Device successfully moved', 'OK');
      });
  }

  allowDrop(event: DragEvent) {
    this.deviceManagement.allowDroppingDraggedItem(event);
  }

  preventDrop(event: DragEvent) {
    this.deviceManagement.preventDroppingDraggedItem(event);
  }

  openMultiDownlink() {
    this.deviceManagement.setView('multi-downlink');
    this.router.navigateByUrl(`home/device/${this.deviceManagement.view}`);
  }

  openManager() {
    this.deviceManagement.setView('manager');
    this.router.navigate([`/home/device/${this.deviceManagement.view}`], {
      queryParams: { path: this.deviceManagement.path },
    });
  }
}
