import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  Calculation,
  DecodingConfiguration,
  Field,
  ParserElement,
  Variable,
} from 'src/models/device-type.models';
import { ParserCreationService } from './../parser-creation.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { getExampleValue, measurementTypes } from '../parser-creation-helper';
import { formulaValidator } from './formula-validator.directive';
import {
  variableAlreadyExistValidator,
  variablesValidator,
} from './variables-validator.directive';
@UntilDestroy()
@Component({
  selector: 'app-single-parser-editor',
  templateUrl: './single-parser-editor.component.html',
  styleUrls: ['./single-parser-editor.component.scss'],
})
export class SingleParserEditorComponent implements OnInit {
  @Input() config: DecodingConfiguration;
  @Input() config_id: string;
  @Output() configModified = new EventEmitter<DecodingConfiguration>();
  parserElementBitRanges: Array<{ max: number; min: number }> = [];
  form = this.fb.group({
    parserElements: this.fb.array([
      this.fb.group({} as Required<ParserElement>),
    ]),
    calculations: this.fb.array([this.fb.group({} as Calculation)]),
  });

  variables$: BehaviorSubject<{ [key: string]: Variable }> =
    new BehaviorSubject({});

  variablesNames$: Observable<string[]>;
  jsonExample;

  constructor(
    private fb: NonNullableFormBuilder,
    public dialog: MatDialog,
    public parserCreation: ParserCreationService,
  ) {
    this.form.controls.parserElements.clear();
    this.form.controls.calculations.clear();

    this.variablesNames$ = this.variables$.pipe(
      untilDestroyed(this),
      map((variables) => Object.keys(variables)),
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
      ),
    );
  }

  ngOnInit(): void {
    this.autoFillForm();
    this.handleFormValueChanges.subscribe();

    this.variablesNames$.subscribe(() => {
      for (const calculation of this.calculations.controls) {
        calculation.get('formula')?.updateValueAndValidity();
      }
    });
  }

  public emitConfig() {
    const variables = this.variables$.getValue(); // Access current value
    const config = { ...this.form.getRawValue(), variables: variables };
    this.configModified.emit(config);
  }

  public updateVariableType(newType: string, variableName: string) {
    const variables = this.variables$.getValue();
    variables[variableName].measurement_type = newType;
    this.variables$.next(variables);
    this.emitConfig();
  }

  public updateVariableUnit(
    newUnit: { sign: string; type: string },
    variableName: string,
  ) {
    const variables = this.variables$.getValue();
    variables[variableName].unit = newUnit.sign;
    variables[variableName].type = newUnit.type;
    this.variables$.next(variables);
    this.emitConfig();
  }

  public getSelectedUnit(measurement_type: string, unit_sign: string) {
    return this.getMeasurementTypeUnits(measurement_type).find(
      (unit) => unit.sign === unit_sign,
    );
  }

  public get measurementTypeNames(): string[] {
    return Object.keys(measurementTypes);
  }

  public getMeasurementTypeUnits(
    type,
  ): Array<{ sign: string; detail: string; type: string }> {
    return measurementTypes[type] ?? [];
  }

  public get sortedVariableValues() {
    const variables = this.variables$.getValue();
    return Object.values(variables).sort((a, b) => a.order - b.order);
  }

  public augmentVariableOrderAtIndex(index: number) {
    const variables = this.variables$.getValue();
    if (index < Object.keys(variables).length - 1) {
      const sortedVars = this.sortedVariableValues;
      variables[sortedVars[index].name].order += 1;
      variables[sortedVars[index + 1].name].order -= 1;
      this.variables$.next(variables);
    }
    this.emitConfig();
  }

  public decreaseVariableOrderAtIndex(index: number) {
    const variables = this.variables$.getValue();
    if (index > 0) {
      const sortedVars = this.sortedVariableValues;
      variables[sortedVars[index].name].order -= 1;
      variables[sortedVars[index - 1].name].order += 1;
      this.variables$.next(variables);
    }
    this.emitConfig();
  }

  public updateVariableBusinessField(selected: boolean, varName: string) {
    const variables = this.variables$.getValue();
    variables[varName].write_business_field = selected;
    this.variables$.next(variables);
    this.emitConfig();
  }

  public updateVariableBusinessFieldUnit(selected: boolean, varName: string) {
    const variables = this.variables$.getValue();
    variables[varName].write_business_field_unit = selected;
    this.variables$.next(variables);
    this.emitConfig();
  }

  public get parserElements() {
    return this.form.controls.parserElements;
  }

  public addParserElement(pE?: ParserElement) {
    const parserElementForm = this.fb.group({
      bits: [
        pE?.bits ?? 8,
        [Validators.required, Validators.pattern('^[0-9]+$')],
      ],
      type: [pE?.type ?? 'int', Validators.required],
      target: [
        pE?.target ?? '',
        [Validators.required, Validators.pattern('^[0-9a-z_]+$')],
        [variableAlreadyExistValidator(this.variablesNames$)],
      ],
      littleEndian: [pE?.littleEndian ?? false],
      signed: [pE?.signed ?? false],
      signComplement: [pE?.signComplement ?? '1'],
    });

    this.parserElements.push(parserElementForm);
  }

  public onRemoveParserElement(index: number) {
    this.parserElements.removeAt(index);
    this.updateParserElementBitRanges();
  }

  public showLittle(value: { [key: string]: string }) {
    return value.type === 'float' || value.type === 'int';
  }

  public showSigned(value: { [key: string]: string }) {
    return value.type === 'int';
  }

  public showComplement(value: { [key: string]: string }) {
    return value.signed;
  }

  public get calculations() {
    return this.form.controls.calculations;
  }

  public addCalculation(cal?: Calculation) {
    const calculationForm = this.fb.group({
      formula: [
        cal?.formula ?? '',
        [Validators.required, formulaValidator],
        [variablesValidator(this.variablesNames$)],
      ],
      target: [
        cal?.target ?? '',
        [Validators.required, Validators.pattern('^[0-9a-z_]+$')],
        [variableAlreadyExistValidator(this.variablesNames$)],
      ],
    });

    this.calculations.push(calculationForm);
  }

  public onRemoveCalculation(index: number) {
    this.calculations.removeAt(index);
  }

  private get handleFormValueChanges() {
    return this.form.valueChanges.pipe(
      tap(() => {
        this.updateParserElementBitRanges();
      }),
      map(() => this.newVariableNames),
      tap((newVarNames) => {
        this.addVariables(newVarNames);
        this.removeUnusedVariables(newVarNames);
      }),
      tap(() => this.emitConfig()),
    );
  }

  private updateParserElementBitRanges() {
    const ranges: Array<{ max: number; min: number }> = [];
    let min = 0;
    let max = -1;

    this.parserElements.controls.forEach((ctrl, i, arr) => {
      if (i !== 0) min = ranges[i - 1].min + arr[i - 1].getRawValue().bits;
      max = max + ctrl.getRawValue().bits;
      ranges.push({ max: max, min: min });
    });

    this.parserElementBitRanges = ranges;
  }

  private get newVariableNames(): string[] {
    return [
      ...this.parserElements.getRawValue().map((pE) => pE.target),
      ...this.calculations.getRawValue().map((cal) => cal.target),
    ].filter((name) => !!name);
  }

  private addVariables(varNames: string[]): void {
    const variables = this.variables$.getValue();
    varNames.forEach((varName) => {
      if (!variables[varName]) {
        variables[varName] = {
          name: varName,
          type: '',
          measurement_type: '',
          unit: '',
          order: Object.keys(variables).length + 1,
          write_business_field_unit: true,
          write_business_field: true,
        };
      }
    });
    this.variables$.next(variables);
  }

  private removeUnusedVariables(varNames: string[]): void {
    const variables = this.variables$.getValue();
    Object.values(variables).forEach((v) => {
      if (!varNames.includes(v.name) && !v.from_source) {
        delete variables[v.name];
        this.updateVariableOrderAfterRemoveAt(v.order);
      }
    });
    this.variables$.next(variables);
  }

  private updateVariableOrderAfterRemoveAt(removed: number) {
    const variables = this.variables$.getValue();
    Object.values(variables).forEach((v) => {
      if (v.order > removed) {
        variables[v.name].order -= 1;
      }
    });
    this.variables$.next(variables);
  }

  private autoFillForm() {
    this.config.parserElements.length > 0
      ? this.config.parserElements.forEach((pE) => this.addParserElement(pE))
      : this.addParserElement();

    this.config.calculations.length > 0
      ? this.config.calculations.forEach((cal) => this.addCalculation(cal))
      : this.addCalculation();

    this.variables$.next(this.config.variables);
  }

  get businessFields(): Field[] {
    const business_fields: Field[] = [];
    this.sortedVariableValues.forEach((variable: Variable) => {
      if (variable.write_business_field)
        business_fields.push(this.mapVariableToBusinessField(variable));
      if (variable.write_business_field_unit)
        business_fields.push(this.mapVariableToUnitBusinessField(variable));
    });
    return business_fields;
  }

  get buildJsonExample() {
    const res = {};
    this.businessFields.forEach(
      (field) => (res[field.name] = getExampleValue(field.type)),
    );
    return res;
  }

  mapVariableToBusinessField(variable: Variable): Field {
    return {
      name: variable.name,
      type: variable.type as 'number' | 'string' | 'boolean',
    };
  }

  mapVariableToUnitBusinessField(variable: Variable): Field {
    return { name: variable.name + '_unit', type: 'string' };
  }

  openJsonPreview(templateRef: TemplateRef<unknown>) {
    this.jsonExample = this.buildJsonExample;
    this.dialog
      .open(templateRef)
      .afterClosed()
      .subscribe(() => (this.jsonExample = undefined));
  }
}
