import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { first, switchMap } from 'rxjs/operators';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { Site } from 'src/models/site.models';
import { AuthService } from '../../../services/state/auth/auth.service';
import { DataLoaderService } from '../../../services/state/data/data-loader.service';
import { SiteCreationComponent } from './site-creation/site-creation.component';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
})
export class SiteComponent {
  screensizeIndex$ = this.interfaceState.breakpointIndex$;
  filteredSites$ = this.data.sites$;
  users$ = this.data.users$;
  sites$ = this.data.sites$;
  constructor(
    private data: DataStoreService,
    private interfaceState: InterfaceStateService,
    private dataLoader: DataLoaderService,
    public auth: AuthService,
    private dialog: MatDialog,
  ) {
    this._loadData();
  }

  private _loadData() {
    combineLatest([
      this.data.users$.pipe(
        switchMap((users) => (users ? of(users) : this.dataLoader.loadUsers())),
      ),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
      ),
    ])
      .pipe(first())
      .subscribe();
  }

  public updateFilteredSites(sites: Site[]): void {
    this.filteredSites$ = of(sites);
  }

  public editSite(siteTag: Site): void {
    this.dialog.open(SiteCreationComponent, { data: siteTag });
  }

  public createSite() {
    this.dialog.open(SiteCreationComponent, {});
  }
}
