<ng-container *appLoader="loaded$; with: hierarchyLoaded">
  <div class="list-header mat-typography mat-elevation-z3">
    <h4 class="list-title">Site List</h4>
  </div>
  <div class="tree mat-typography mat-elevation-z3">
    <app-hierarchy-tree
      [selectedNode]="selectedNode$ | async"
    ></app-hierarchy-tree>
  </div>
  <div class="search mat-elevation-z3">
    <mat-form-field>
      <mat-label>Instant search</mat-label>
      <input
        type="text"
        matInput
        [matAutocomplete]="auto"
        [formControl]="searchControl"
      />
      <mat-icon matSuffix (click)="resetSearch()">close</mat-icon>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options$ | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="details mat-typography mat-elevation-z4">
    <router-outlet></router-outlet>
  </div>
</ng-container>
